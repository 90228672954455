body,html {
  margin: 0;
  font-family: 'Quicksand', monospace;
  background: rgb(22,25,34);
  background: radial-gradient(circle, #0e0f15 0%, #161922 82%);
  color: #fff;
}

.h {
  font-family: 'Yeseva One', cursive;
}

.ht {
  color:#f24000;
}
.container {
  padding: 128px;
  width: 100%;
  // overflow: hidden;
  box-sizing: border-box;
}
.banner {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  position: relative;

  .text {
    font-size: 5em;
    display: block;
    color: #c4c5c9;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.5);
  }

  .subtext {
    display: block;
    box-sizing: border-box;
    margin-top: 24px;
    font-size: 1.2em;
    line-height: 28px;
    color: #a7a9af;
    // letter-spacing: .8px;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    b {
      color: rgb(99, 23, 189);
    }
  }

  .foottext {
    font-size: 1.8em;
    margin-top: 64px;
    display: flex;
    align-items: center;
    
    img {
      width: 64px;
      border-radius: 48px;
      margin-right: 16px;
    }
  }
}

.experience {
  .experienceTile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50vh;
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  
    .text {
      width: 420px;
      min-width: 420px;
      // margin-top: 60vh;
      .h {
        font-size: 2.4em;
        margin-bottom: 16px;
      }
      p {
        line-height: 32px;
        font-size: 1.2em;
      }
    }
    img {
      flex:1;
      max-width: 50%;
      margin-right: 32px;
      position: sticky;
      top: 10vh;
      border-radius: 8px;
      border-bottom: 16px solid #6317bd;
      border-right: 8px solid #6317bd;
      box-shadow: 0px 16px 24px rgba(0,0,0,0.9);
      transition: all .3s ease;
    }
    img:hover {
      will-change: transform;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(-11.0553deg) rotateY(10.9982deg) rotateZ(2deg) skew(0deg, 0deg) scale(1.1);
      transform-style: preserve-3d;
    }
  }
}

.intro {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  background: #161922;
  color: #c4c5c9;

  .text {
    flex: 1;
    margin-right: 48px;
    .h {
      font-size: 2em;
      margin-bottom: 16px;
    }
    .subtext {
      font-size:2.4em;
      font-weight: bold;
    }
    p {
      line-height: 32px;
    }
    b {
      color:#f24000;
    }
  }
  img {
    flex:1;
    width: 50%;
    border-radius: 32px;
    filter: grayscale(1);
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    padding: 24px;
  }
  .banner {
    .text {
      font-size: 2.3em;
    }
    .subtext {
      font-size: 1em;
    }
    .foottext {
      font-size: 1.4em;
      
      img {
        width: 48px;
        border-radius: 48px;
        margin-right: 16px;
      }
    }
  }
  .experience {
    margin-top: 128px;
    .experienceTile {
      flex-direction: column-reverse;
      margin-bottom: 0;
      &:nth-child(odd) {
        flex-direction: column-reverse;
      }
      .text {
        width: auto;
        min-width: auto;
        margin-top: 48px;
        margin-bottom: 256px;
        .h {
          font-size: 2em;
        }
        p {
          line-height: 32px;
          font-size: 1em;
        }
      }
      img {
        width: 100%;
        min-width: auto;
        max-width: none;
        border-right: none;
        top: auto;
        bottom: 24px;
      }
    }
  }
  .intro {
    flex-direction: column-reverse;
    .h {
      font-size: 1.3em;
    }
    .subtext {
      font-size: 1.8em;
    }
    .text {
      min-width: auto;
      max-width: auto;
      width:auto;
      margin-right: 0;
    }
    img {
      width: 100%;
      max-width: none;
      position: relative;
      top: -64px;
    }
  }
}





// fireflies

.fireflies {
  position:fixed;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
}

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before, .firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}
.firefly::before {
  background: #6317bd;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}
.firefly::after {
  background: #f24000;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw #f24000;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}
.firefly:nth-child(1)::before {
  animation-duration: 9s;
}
.firefly:nth-child(1)::after {
  animation-duration: 9s, 5282ms;
  animation-delay: 0ms, 3826ms;
}

@keyframes move1 {
  0% {
    transform: translateX(-19vw) translateY(-43vh) scale(0.46);
  }
  3.7037037037% {
    transform: translateX(-1vw) translateY(50vh) scale(0.65);
  }
  7.4074074074% {
    transform: translateX(-15vw) translateY(17vh) scale(0.87);
  }
  11.1111111111% {
    transform: translateX(-32vw) translateY(-32vh) scale(0.9);
  }
  14.8148148148% {
    transform: translateX(-5vw) translateY(14vh) scale(0.4);
  }
  18.5185185185% {
    transform: translateX(43vw) translateY(-12vh) scale(0.91);
  }
  22.2222222222% {
    transform: translateX(-35vw) translateY(-5vh) scale(0.62);
  }
  25.9259259259% {
    transform: translateX(27vw) translateY(50vh) scale(0.49);
  }
  29.6296296296% {
    transform: translateX(42vw) translateY(-3vh) scale(0.95);
  }
  33.3333333333% {
    transform: translateX(19vw) translateY(33vh) scale(0.5);
  }
  37.037037037% {
    transform: translateX(-37vw) translateY(32vh) scale(0.54);
  }
  40.7407407407% {
    transform: translateX(-29vw) translateY(15vh) scale(0.84);
  }
  44.4444444444% {
    transform: translateX(3vw) translateY(-38vh) scale(0.51);
  }
  48.1481481481% {
    transform: translateX(12vw) translateY(26vh) scale(0.46);
  }
  51.8518518519% {
    transform: translateX(48vw) translateY(-33vh) scale(0.78);
  }
  55.5555555556% {
    transform: translateX(-30vw) translateY(-34vh) scale(0.78);
  }
  59.2592592593% {
    transform: translateX(27vw) translateY(-31vh) scale(0.84);
  }
  62.962962963% {
    transform: translateX(-42vw) translateY(29vh) scale(0.55);
  }
  66.6666666667% {
    transform: translateX(18vw) translateY(26vh) scale(0.71);
  }
  70.3703703704% {
    transform: translateX(49vw) translateY(2vh) scale(0.44);
  }
  74.0740740741% {
    transform: translateX(-11vw) translateY(-12vh) scale(0.99);
  }
  77.7777777778% {
    transform: translateX(-46vw) translateY(-48vh) scale(0.27);
  }
  81.4814814815% {
    transform: translateX(6vw) translateY(17vh) scale(0.88);
  }
  85.1851851852% {
    transform: translateX(12vw) translateY(21vh) scale(0.31);
  }
  88.8888888889% {
    transform: translateX(49vw) translateY(-23vh) scale(0.82);
  }
  92.5925925926% {
    transform: translateX(-28vw) translateY(10vh) scale(0.71);
  }
  96.2962962963% {
    transform: translateX(18vw) translateY(37vh) scale(0.54);
  }
  100% {
    transform: translateX(-33vw) translateY(27vh) scale(0.5);
  }
}
.firefly:nth-child(2) {
  animation-name: move2;
}
.firefly:nth-child(2)::before {
  animation-duration: 11s;
}
.firefly:nth-child(2)::after {
  animation-duration: 11s, 6573ms;
  animation-delay: 0ms, 3317ms;
}

@keyframes move2 {
  0% {
    transform: translateX(3vw) translateY(50vh) scale(0.49);
  }
  5.5555555556% {
    transform: translateX(40vw) translateY(16vh) scale(0.65);
  }
  11.1111111111% {
    transform: translateX(-45vw) translateY(-36vh) scale(0.9);
  }
  16.6666666667% {
    transform: translateX(-9vw) translateY(-4vh) scale(0.33);
  }
  22.2222222222% {
    transform: translateX(-1vw) translateY(43vh) scale(0.51);
  }
  27.7777777778% {
    transform: translateX(-8vw) translateY(-10vh) scale(0.41);
  }
  33.3333333333% {
    transform: translateX(44vw) translateY(-5vh) scale(0.94);
  }
  38.8888888889% {
    transform: translateX(45vw) translateY(43vh) scale(0.85);
  }
  44.4444444444% {
    transform: translateX(-25vw) translateY(23vh) scale(0.38);
  }
  50% {
    transform: translateX(-38vw) translateY(8vh) scale(0.49);
  }
  55.5555555556% {
    transform: translateX(23vw) translateY(-31vh) scale(0.58);
  }
  61.1111111111% {
    transform: translateX(-15vw) translateY(-38vh) scale(0.77);
  }
  66.6666666667% {
    transform: translateX(3vw) translateY(-36vh) scale(0.33);
  }
  72.2222222222% {
    transform: translateX(-32vw) translateY(-20vh) scale(0.5);
  }
  77.7777777778% {
    transform: translateX(-48vw) translateY(-36vh) scale(0.39);
  }
  83.3333333333% {
    transform: translateX(0vw) translateY(10vh) scale(0.59);
  }
  88.8888888889% {
    transform: translateX(-39vw) translateY(-32vh) scale(0.67);
  }
  94.4444444444% {
    transform: translateX(7vw) translateY(-13vh) scale(0.36);
  }
  100% {
    transform: translateX(49vw) translateY(14vh) scale(0.57);
  }
}
.firefly:nth-child(3) {
  animation-name: move3;
}
.firefly:nth-child(3)::before {
  animation-duration: 18s;
}
.firefly:nth-child(3)::after {
  animation-duration: 18s, 7058ms;
  animation-delay: 0ms, 5441ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-18vw) translateY(32vh) scale(0.38);
  }
  5% {
    transform: translateX(29vw) translateY(-2vh) scale(1);
  }
  10% {
    transform: translateX(-13vw) translateY(41vh) scale(0.46);
  }
  15% {
    transform: translateX(26vw) translateY(41vh) scale(0.8);
  }
  20% {
    transform: translateX(8vw) translateY(-20vh) scale(0.97);
  }
  25% {
    transform: translateX(50vw) translateY(-10vh) scale(0.4);
  }
  30% {
    transform: translateX(28vw) translateY(-20vh) scale(0.93);
  }
  35% {
    transform: translateX(16vw) translateY(-38vh) scale(0.98);
  }
  40% {
    transform: translateX(-35vw) translateY(-11vh) scale(0.34);
  }
  45% {
    transform: translateX(9vw) translateY(-35vh) scale(0.63);
  }
  50% {
    transform: translateX(-29vw) translateY(-47vh) scale(0.76);
  }
  55% {
    transform: translateX(-7vw) translateY(-46vh) scale(0.69);
  }
  60% {
    transform: translateX(43vw) translateY(37vh) scale(0.99);
  }
  65% {
    transform: translateX(39vw) translateY(18vh) scale(0.78);
  }
  70% {
    transform: translateX(42vw) translateY(-27vh) scale(0.89);
  }
  75% {
    transform: translateX(-5vw) translateY(4vh) scale(0.89);
  }
  80% {
    transform: translateX(-10vw) translateY(-39vh) scale(0.35);
  }
  85% {
    transform: translateX(-8vw) translateY(-41vh) scale(0.97);
  }
  90% {
    transform: translateX(41vw) translateY(21vh) scale(0.63);
  }
  95% {
    transform: translateX(-10vw) translateY(13vh) scale(0.59);
  }
  100% {
    transform: translateX(-7vw) translateY(-1vh) scale(0.72);
  }
}
.firefly:nth-child(4) {
  animation-name: move4;
}
.firefly:nth-child(4)::before {
  animation-duration: 16s;
}
.firefly:nth-child(4)::after {
  animation-duration: 16s, 6284ms;
  animation-delay: 0ms, 4460ms;
}

@keyframes move4 {
  0% {
    transform: translateX(15vw) translateY(25vh) scale(0.77);
  }
  5.2631578947% {
    transform: translateX(39vw) translateY(39vh) scale(0.44);
  }
  10.5263157895% {
    transform: translateX(32vw) translateY(-2vh) scale(0.56);
  }
  15.7894736842% {
    transform: translateX(-25vw) translateY(43vh) scale(0.77);
  }
  21.0526315789% {
    transform: translateX(-14vw) translateY(0vh) scale(0.41);
  }
  26.3157894737% {
    transform: translateX(24vw) translateY(-28vh) scale(0.65);
  }
  31.5789473684% {
    transform: translateX(25vw) translateY(22vh) scale(0.68);
  }
  36.8421052632% {
    transform: translateX(-7vw) translateY(-2vh) scale(0.71);
  }
  42.1052631579% {
    transform: translateX(26vw) translateY(-29vh) scale(0.42);
  }
  47.3684210526% {
    transform: translateX(-1vw) translateY(-22vh) scale(0.45);
  }
  52.6315789474% {
    transform: translateX(31vw) translateY(-20vh) scale(0.45);
  }
  57.8947368421% {
    transform: translateX(-15vw) translateY(48vh) scale(0.87);
  }
  63.1578947368% {
    transform: translateX(-28vw) translateY(15vh) scale(0.42);
  }
  68.4210526316% {
    transform: translateX(28vw) translateY(13vh) scale(0.54);
  }
  73.6842105263% {
    transform: translateX(-27vw) translateY(-46vh) scale(0.58);
  }
  78.9473684211% {
    transform: translateX(-30vw) translateY(35vh) scale(0.57);
  }
  84.2105263158% {
    transform: translateX(-15vw) translateY(43vh) scale(0.37);
  }
  89.4736842105% {
    transform: translateX(-48vw) translateY(40vh) scale(0.66);
  }
  94.7368421053% {
    transform: translateX(31vw) translateY(6vh) scale(0.39);
  }
  100% {
    transform: translateX(40vw) translateY(-6vh) scale(0.4);
  }
}
.firefly:nth-child(5) {
  animation-name: move5;
}
.firefly:nth-child(5)::before {
  animation-duration: 12s;
}
.firefly:nth-child(5)::after {
  animation-duration: 12s, 6695ms;
  animation-delay: 0ms, 8428ms;
}

@keyframes move5 {
  0% {
    transform: translateX(3vw) translateY(-19vh) scale(0.66);
  }
  3.8461538462% {
    transform: translateX(-41vw) translateY(7vh) scale(0.56);
  }
  7.6923076923% {
    transform: translateX(31vw) translateY(-7vh) scale(0.26);
  }
  11.5384615385% {
    transform: translateX(26vw) translateY(-41vh) scale(0.7);
  }
  15.3846153846% {
    transform: translateX(-43vw) translateY(11vh) scale(0.68);
  }
  19.2307692308% {
    transform: translateX(31vw) translateY(-32vh) scale(0.8);
  }
  23.0769230769% {
    transform: translateX(6vw) translateY(42vh) scale(0.65);
  }
  26.9230769231% {
    transform: translateX(-31vw) translateY(-40vh) scale(0.39);
  }
  30.7692307692% {
    transform: translateX(-46vw) translateY(-37vh) scale(0.37);
  }
  34.6153846154% {
    transform: translateX(19vw) translateY(-34vh) scale(0.91);
  }
  38.4615384615% {
    transform: translateX(-30vw) translateY(-42vh) scale(0.47);
  }
  42.3076923077% {
    transform: translateX(36vw) translateY(38vh) scale(0.69);
  }
  46.1538461538% {
    transform: translateX(7vw) translateY(9vh) scale(0.96);
  }
  50% {
    transform: translateX(45vw) translateY(-33vh) scale(0.44);
  }
  53.8461538462% {
    transform: translateX(35vw) translateY(47vh) scale(0.54);
  }
  57.6923076923% {
    transform: translateX(-21vw) translateY(-26vh) scale(0.56);
  }
  61.5384615385% {
    transform: translateX(33vw) translateY(-17vh) scale(0.51);
  }
  65.3846153846% {
    transform: translateX(1vw) translateY(44vh) scale(0.4);
  }
  69.2307692308% {
    transform: translateX(23vw) translateY(46vh) scale(0.79);
  }
  73.0769230769% {
    transform: translateX(12vw) translateY(48vh) scale(0.87);
  }
  76.9230769231% {
    transform: translateX(-34vw) translateY(-26vh) scale(0.42);
  }
  80.7692307692% {
    transform: translateX(38vw) translateY(-40vh) scale(0.69);
  }
  84.6153846154% {
    transform: translateX(50vw) translateY(-17vh) scale(0.79);
  }
  88.4615384615% {
    transform: translateX(-35vw) translateY(-1vh) scale(0.53);
  }
  92.3076923077% {
    transform: translateX(43vw) translateY(21vh) scale(0.7);
  }
  96.1538461538% {
    transform: translateX(26vw) translateY(48vh) scale(0.93);
  }
  100% {
    transform: translateX(-4vw) translateY(-30vh) scale(0.66);
  }
}
.firefly:nth-child(6) {
  animation-name: move6;
}
.firefly:nth-child(6)::before {
  animation-duration: 18s;
}
.firefly:nth-child(6)::after {
  animation-duration: 18s, 7821ms;
  animation-delay: 0ms, 886ms;
}

@keyframes move6 {
  0% {
    transform: translateX(43vw) translateY(-49vh) scale(0.82);
  }
  4.347826087% {
    transform: translateX(18vw) translateY(29vh) scale(0.84);
  }
  8.6956521739% {
    transform: translateX(27vw) translateY(-22vh) scale(0.85);
  }
  13.0434782609% {
    transform: translateX(5vw) translateY(-33vh) scale(0.37);
  }
  17.3913043478% {
    transform: translateX(-25vw) translateY(16vh) scale(0.77);
  }
  21.7391304348% {
    transform: translateX(46vw) translateY(-49vh) scale(0.87);
  }
  26.0869565217% {
    transform: translateX(21vw) translateY(-23vh) scale(0.76);
  }
  30.4347826087% {
    transform: translateX(2vw) translateY(33vh) scale(0.92);
  }
  34.7826086957% {
    transform: translateX(-3vw) translateY(-15vh) scale(0.53);
  }
  39.1304347826% {
    transform: translateX(-18vw) translateY(26vh) scale(0.75);
  }
  43.4782608696% {
    transform: translateX(-15vw) translateY(27vh) scale(0.38);
  }
  47.8260869565% {
    transform: translateX(-46vw) translateY(-39vh) scale(0.26);
  }
  52.1739130435% {
    transform: translateX(35vw) translateY(18vh) scale(0.73);
  }
  56.5217391304% {
    transform: translateX(-31vw) translateY(-40vh) scale(0.64);
  }
  60.8695652174% {
    transform: translateX(-11vw) translateY(36vh) scale(0.76);
  }
  65.2173913043% {
    transform: translateX(10vw) translateY(-17vh) scale(0.41);
  }
  69.5652173913% {
    transform: translateX(-16vw) translateY(-35vh) scale(0.96);
  }
  73.9130434783% {
    transform: translateX(-15vw) translateY(9vh) scale(0.89);
  }
  78.2608695652% {
    transform: translateX(48vw) translateY(31vh) scale(0.81);
  }
  82.6086956522% {
    transform: translateX(-6vw) translateY(28vh) scale(0.73);
  }
  86.9565217391% {
    transform: translateX(6vw) translateY(11vh) scale(0.71);
  }
  91.3043478261% {
    transform: translateX(33vw) translateY(-5vh) scale(0.76);
  }
  95.652173913% {
    transform: translateX(-29vw) translateY(21vh) scale(0.94);
  }
  100% {
    transform: translateX(-35vw) translateY(-43vh) scale(0.67);
  }
}
.firefly:nth-child(7) {
  animation-name: move7;
}
.firefly:nth-child(7)::before {
  animation-duration: 12s;
}
.firefly:nth-child(7)::after {
  animation-duration: 12s, 8661ms;
  animation-delay: 0ms, 6933ms;
}

@keyframes move7 {
  0% {
    transform: translateX(-34vw) translateY(-19vh) scale(0.29);
  }
  5.5555555556% {
    transform: translateX(-31vw) translateY(8vh) scale(0.65);
  }
  11.1111111111% {
    transform: translateX(-23vw) translateY(13vh) scale(0.7);
  }
  16.6666666667% {
    transform: translateX(-1vw) translateY(-23vh) scale(0.61);
  }
  22.2222222222% {
    transform: translateX(27vw) translateY(38vh) scale(0.41);
  }
  27.7777777778% {
    transform: translateX(10vw) translateY(11vh) scale(1);
  }
  33.3333333333% {
    transform: translateX(-11vw) translateY(-32vh) scale(0.84);
  }
  38.8888888889% {
    transform: translateX(-9vw) translateY(-37vh) scale(0.31);
  }
  44.4444444444% {
    transform: translateX(-2vw) translateY(-26vh) scale(0.7);
  }
  50% {
    transform: translateX(42vw) translateY(50vh) scale(0.97);
  }
  55.5555555556% {
    transform: translateX(9vw) translateY(32vh) scale(0.62);
  }
  61.1111111111% {
    transform: translateX(7vw) translateY(-5vh) scale(0.55);
  }
  66.6666666667% {
    transform: translateX(-32vw) translateY(29vh) scale(0.82);
  }
  72.2222222222% {
    transform: translateX(25vw) translateY(-41vh) scale(0.96);
  }
  77.7777777778% {
    transform: translateX(-14vw) translateY(-31vh) scale(0.75);
  }
  83.3333333333% {
    transform: translateX(-29vw) translateY(15vh) scale(0.8);
  }
  88.8888888889% {
    transform: translateX(-43vw) translateY(-42vh) scale(0.47);
  }
  94.4444444444% {
    transform: translateX(10vw) translateY(45vh) scale(0.26);
  }
  100% {
    transform: translateX(-25vw) translateY(35vh) scale(0.88);
  }
}
.firefly:nth-child(8) {
  animation-name: move8;
}
.firefly:nth-child(8)::before {
  animation-duration: 11s;
}
.firefly:nth-child(8)::after {
  animation-duration: 11s, 10991ms;
  animation-delay: 0ms, 1610ms;
}

@keyframes move8 {
  0% {
    transform: translateX(-40vw) translateY(-12vh) scale(0.68);
  }
  5.2631578947% {
    transform: translateX(13vw) translateY(-48vh) scale(0.54);
  }
  10.5263157895% {
    transform: translateX(16vw) translateY(-34vh) scale(0.51);
  }
  15.7894736842% {
    transform: translateX(-39vw) translateY(-19vh) scale(0.53);
  }
  21.0526315789% {
    transform: translateX(3vw) translateY(-38vh) scale(0.26);
  }
  26.3157894737% {
    transform: translateX(-40vw) translateY(-19vh) scale(0.98);
  }
  31.5789473684% {
    transform: translateX(49vw) translateY(3vh) scale(0.83);
  }
  36.8421052632% {
    transform: translateX(22vw) translateY(-14vh) scale(0.85);
  }
  42.1052631579% {
    transform: translateX(-2vw) translateY(0vh) scale(0.83);
  }
  47.3684210526% {
    transform: translateX(-16vw) translateY(22vh) scale(0.83);
  }
  52.6315789474% {
    transform: translateX(28vw) translateY(38vh) scale(0.75);
  }
  57.8947368421% {
    transform: translateX(48vw) translateY(-17vh) scale(0.57);
  }
  63.1578947368% {
    transform: translateX(-11vw) translateY(-14vh) scale(0.5);
  }
  68.4210526316% {
    transform: translateX(21vw) translateY(27vh) scale(0.53);
  }
  73.6842105263% {
    transform: translateX(32vw) translateY(23vh) scale(0.64);
  }
  78.9473684211% {
    transform: translateX(-2vw) translateY(37vh) scale(0.7);
  }
  84.2105263158% {
    transform: translateX(-34vw) translateY(20vh) scale(0.46);
  }
  89.4736842105% {
    transform: translateX(-23vw) translateY(39vh) scale(0.27);
  }
  94.7368421053% {
    transform: translateX(22vw) translateY(-6vh) scale(0.64);
  }
  100% {
    transform: translateX(3vw) translateY(43vh) scale(0.28);
  }
}
.firefly:nth-child(9) {
  animation-name: move9;
}
.firefly:nth-child(9)::before {
  animation-duration: 17s;
}
.firefly:nth-child(9)::after {
  animation-duration: 17s, 9125ms;
  animation-delay: 0ms, 928ms;
}

@keyframes move9 {
  0% {
    transform: translateX(-19vw) translateY(13vh) scale(0.67);
  }
  5.5555555556% {
    transform: translateX(33vw) translateY(-39vh) scale(0.53);
  }
  11.1111111111% {
    transform: translateX(-3vw) translateY(4vh) scale(0.42);
  }
  16.6666666667% {
    transform: translateX(11vw) translateY(45vh) scale(0.71);
  }
  22.2222222222% {
    transform: translateX(32vw) translateY(13vh) scale(0.84);
  }
  27.7777777778% {
    transform: translateX(-16vw) translateY(42vh) scale(0.43);
  }
  33.3333333333% {
    transform: translateX(40vw) translateY(42vh) scale(0.35);
  }
  38.8888888889% {
    transform: translateX(-36vw) translateY(-47vh) scale(0.26);
  }
  44.4444444444% {
    transform: translateX(10vw) translateY(50vh) scale(0.29);
  }
  50% {
    transform: translateX(-22vw) translateY(-27vh) scale(0.48);
  }
  55.5555555556% {
    transform: translateX(34vw) translateY(-26vh) scale(0.48);
  }
  61.1111111111% {
    transform: translateX(25vw) translateY(1vh) scale(0.56);
  }
  66.6666666667% {
    transform: translateX(39vw) translateY(-36vh) scale(0.83);
  }
  72.2222222222% {
    transform: translateX(9vw) translateY(14vh) scale(0.39);
  }
  77.7777777778% {
    transform: translateX(14vw) translateY(15vh) scale(0.68);
  }
  83.3333333333% {
    transform: translateX(25vw) translateY(-12vh) scale(0.29);
  }
  88.8888888889% {
    transform: translateX(40vw) translateY(3vh) scale(0.96);
  }
  94.4444444444% {
    transform: translateX(41vw) translateY(7vh) scale(0.8);
  }
  100% {
    transform: translateX(15vw) translateY(34vh) scale(0.3);
  }
}
.firefly:nth-child(10) {
  animation-name: move10;
}
.firefly:nth-child(10)::before {
  animation-duration: 13s;
}
.firefly:nth-child(10)::after {
  animation-duration: 13s, 8294ms;
  animation-delay: 0ms, 7081ms;
}

@keyframes move10 {
  0% {
    transform: translateX(-6vw) translateY(-6vh) scale(0.79);
  }
  4.347826087% {
    transform: translateX(21vw) translateY(14vh) scale(0.31);
  }
  8.6956521739% {
    transform: translateX(2vw) translateY(-1vh) scale(0.56);
  }
  13.0434782609% {
    transform: translateX(-19vw) translateY(14vh) scale(0.98);
  }
  17.3913043478% {
    transform: translateX(29vw) translateY(42vh) scale(0.74);
  }
  21.7391304348% {
    transform: translateX(35vw) translateY(-43vh) scale(0.89);
  }
  26.0869565217% {
    transform: translateX(-10vw) translateY(-42vh) scale(0.97);
  }
  30.4347826087% {
    transform: translateX(-12vw) translateY(14vh) scale(0.99);
  }
  34.7826086957% {
    transform: translateX(-16vw) translateY(-17vh) scale(0.66);
  }
  39.1304347826% {
    transform: translateX(-24vw) translateY(-22vh) scale(0.36);
  }
  43.4782608696% {
    transform: translateX(-17vw) translateY(38vh) scale(1);
  }
  47.8260869565% {
    transform: translateX(-12vw) translateY(19vh) scale(0.56);
  }
  52.1739130435% {
    transform: translateX(-36vw) translateY(35vh) scale(0.41);
  }
  56.5217391304% {
    transform: translateX(26vw) translateY(14vh) scale(0.46);
  }
  60.8695652174% {
    transform: translateX(30vw) translateY(-35vh) scale(0.55);
  }
  65.2173913043% {
    transform: translateX(45vw) translateY(-2vh) scale(0.76);
  }
  69.5652173913% {
    transform: translateX(-40vw) translateY(42vh) scale(0.62);
  }
  73.9130434783% {
    transform: translateX(14vw) translateY(-21vh) scale(0.9);
  }
  78.2608695652% {
    transform: translateX(-25vw) translateY(19vh) scale(0.31);
  }
  82.6086956522% {
    transform: translateX(3vw) translateY(1vh) scale(0.46);
  }
  86.9565217391% {
    transform: translateX(-8vw) translateY(-19vh) scale(0.56);
  }
  91.3043478261% {
    transform: translateX(-5vw) translateY(-3vh) scale(0.44);
  }
  95.652173913% {
    transform: translateX(3vw) translateY(-5vh) scale(0.96);
  }
  100% {
    transform: translateX(38vw) translateY(-26vh) scale(0.31);
  }
}
.firefly:nth-child(11) {
  animation-name: move11;
}
.firefly:nth-child(11)::before {
  animation-duration: 13s;
}
.firefly:nth-child(11)::after {
  animation-duration: 13s, 5173ms;
  animation-delay: 0ms, 6428ms;
}

@keyframes move11 {
  0% {
    transform: translateX(6vw) translateY(28vh) scale(0.76);
  }
  5.5555555556% {
    transform: translateX(-47vw) translateY(-45vh) scale(0.93);
  }
  11.1111111111% {
    transform: translateX(-21vw) translateY(32vh) scale(0.89);
  }
  16.6666666667% {
    transform: translateX(-41vw) translateY(-42vh) scale(0.71);
  }
  22.2222222222% {
    transform: translateX(7vw) translateY(-27vh) scale(0.79);
  }
  27.7777777778% {
    transform: translateX(-13vw) translateY(32vh) scale(0.58);
  }
  33.3333333333% {
    transform: translateX(36vw) translateY(17vh) scale(0.39);
  }
  38.8888888889% {
    transform: translateX(-37vw) translateY(48vh) scale(0.6);
  }
  44.4444444444% {
    transform: translateX(37vw) translateY(30vh) scale(0.79);
  }
  50% {
    transform: translateX(41vw) translateY(-36vh) scale(0.53);
  }
  55.5555555556% {
    transform: translateX(-24vw) translateY(-41vh) scale(0.55);
  }
  61.1111111111% {
    transform: translateX(35vw) translateY(39vh) scale(0.98);
  }
  66.6666666667% {
    transform: translateX(-49vw) translateY(-8vh) scale(0.78);
  }
  72.2222222222% {
    transform: translateX(25vw) translateY(5vh) scale(0.83);
  }
  77.7777777778% {
    transform: translateX(-42vw) translateY(46vh) scale(0.82);
  }
  83.3333333333% {
    transform: translateX(4vw) translateY(-21vh) scale(0.53);
  }
  88.8888888889% {
    transform: translateX(-4vw) translateY(31vh) scale(0.83);
  }
  94.4444444444% {
    transform: translateX(-43vw) translateY(-26vh) scale(0.86);
  }
  100% {
    transform: translateX(-3vw) translateY(-42vh) scale(0.3);
  }
}
.firefly:nth-child(12) {
  animation-name: move12;
}
.firefly:nth-child(12)::before {
  animation-duration: 13s;
}
.firefly:nth-child(12)::after {
  animation-duration: 13s, 7602ms;
  animation-delay: 0ms, 2390ms;
}

@keyframes move12 {
  0% {
    transform: translateX(-46vw) translateY(26vh) scale(0.49);
  }
  5.2631578947% {
    transform: translateX(39vw) translateY(28vh) scale(0.88);
  }
  10.5263157895% {
    transform: translateX(35vw) translateY(-25vh) scale(0.56);
  }
  15.7894736842% {
    transform: translateX(19vw) translateY(16vh) scale(0.71);
  }
  21.0526315789% {
    transform: translateX(-37vw) translateY(-47vh) scale(0.97);
  }
  26.3157894737% {
    transform: translateX(42vw) translateY(31vh) scale(0.7);
  }
  31.5789473684% {
    transform: translateX(15vw) translateY(-48vh) scale(0.53);
  }
  36.8421052632% {
    transform: translateX(39vw) translateY(-35vh) scale(0.32);
  }
  42.1052631579% {
    transform: translateX(-47vw) translateY(15vh) scale(0.43);
  }
  47.3684210526% {
    transform: translateX(18vw) translateY(18vh) scale(0.91);
  }
  52.6315789474% {
    transform: translateX(34vw) translateY(32vh) scale(1);
  }
  57.8947368421% {
    transform: translateX(50vw) translateY(11vh) scale(0.29);
  }
  63.1578947368% {
    transform: translateX(-48vw) translateY(1vh) scale(0.67);
  }
  68.4210526316% {
    transform: translateX(-9vw) translateY(23vh) scale(0.41);
  }
  73.6842105263% {
    transform: translateX(13vw) translateY(3vh) scale(0.67);
  }
  78.9473684211% {
    transform: translateX(-27vw) translateY(31vh) scale(0.44);
  }
  84.2105263158% {
    transform: translateX(12vw) translateY(-31vh) scale(0.51);
  }
  89.4736842105% {
    transform: translateX(21vw) translateY(-15vh) scale(0.31);
  }
  94.7368421053% {
    transform: translateX(33vw) translateY(-4vh) scale(0.59);
  }
  100% {
    transform: translateX(-21vw) translateY(-25vh) scale(0.53);
  }
}
.firefly:nth-child(13) {
  animation-name: move13;
}
.firefly:nth-child(13)::before {
  animation-duration: 13s;
}
.firefly:nth-child(13)::after {
  animation-duration: 13s, 7628ms;
  animation-delay: 0ms, 7677ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-17vw) translateY(18vh) scale(0.77);
  }
  3.8461538462% {
    transform: translateX(20vw) translateY(-29vh) scale(0.36);
  }
  7.6923076923% {
    transform: translateX(26vw) translateY(37vh) scale(0.95);
  }
  11.5384615385% {
    transform: translateX(48vw) translateY(14vh) scale(0.97);
  }
  15.3846153846% {
    transform: translateX(-11vw) translateY(43vh) scale(0.68);
  }
  19.2307692308% {
    transform: translateX(-16vw) translateY(2vh) scale(0.65);
  }
  23.0769230769% {
    transform: translateX(42vw) translateY(50vh) scale(0.87);
  }
  26.9230769231% {
    transform: translateX(30vw) translateY(-36vh) scale(0.7);
  }
  30.7692307692% {
    transform: translateX(-36vw) translateY(-43vh) scale(0.31);
  }
  34.6153846154% {
    transform: translateX(-35vw) translateY(-47vh) scale(0.88);
  }
  38.4615384615% {
    transform: translateX(13vw) translateY(-29vh) scale(0.48);
  }
  42.3076923077% {
    transform: translateX(-2vw) translateY(29vh) scale(0.41);
  }
  46.1538461538% {
    transform: translateX(41vw) translateY(-33vh) scale(0.86);
  }
  50% {
    transform: translateX(49vw) translateY(39vh) scale(0.35);
  }
  53.8461538462% {
    transform: translateX(-13vw) translateY(-9vh) scale(0.67);
  }
  57.6923076923% {
    transform: translateX(14vw) translateY(0vh) scale(0.28);
  }
  61.5384615385% {
    transform: translateX(-7vw) translateY(-38vh) scale(0.45);
  }
  65.3846153846% {
    transform: translateX(-18vw) translateY(36vh) scale(0.82);
  }
  69.2307692308% {
    transform: translateX(-6vw) translateY(-7vh) scale(0.91);
  }
  73.0769230769% {
    transform: translateX(19vw) translateY(27vh) scale(0.26);
  }
  76.9230769231% {
    transform: translateX(8vw) translateY(-25vh) scale(0.91);
  }
  80.7692307692% {
    transform: translateX(-15vw) translateY(-31vh) scale(0.49);
  }
  84.6153846154% {
    transform: translateX(0vw) translateY(-24vh) scale(0.32);
  }
  88.4615384615% {
    transform: translateX(36vw) translateY(31vh) scale(0.35);
  }
  92.3076923077% {
    transform: translateX(0vw) translateY(39vh) scale(0.35);
  }
  96.1538461538% {
    transform: translateX(23vw) translateY(37vh) scale(0.32);
  }
  100% {
    transform: translateX(7vw) translateY(-32vh) scale(0.56);
  }
}
.firefly:nth-child(14) {
  animation-name: move14;
}
.firefly:nth-child(14)::before {
  animation-duration: 14s;
}
.firefly:nth-child(14)::after {
  animation-duration: 14s, 8869ms;
  animation-delay: 0ms, 6086ms;
}

@keyframes move14 {
  0% {
    transform: translateX(-26vw) translateY(-8vh) scale(0.46);
  }
  5.2631578947% {
    transform: translateX(6vw) translateY(-29vh) scale(0.72);
  }
  10.5263157895% {
    transform: translateX(-42vw) translateY(43vh) scale(0.55);
  }
  15.7894736842% {
    transform: translateX(-24vw) translateY(-40vh) scale(0.97);
  }
  21.0526315789% {
    transform: translateX(-33vw) translateY(-18vh) scale(0.53);
  }
  26.3157894737% {
    transform: translateX(-9vw) translateY(36vh) scale(0.55);
  }
  31.5789473684% {
    transform: translateX(28vw) translateY(-28vh) scale(0.47);
  }
  36.8421052632% {
    transform: translateX(-6vw) translateY(-8vh) scale(0.51);
  }
  42.1052631579% {
    transform: translateX(-46vw) translateY(32vh) scale(0.96);
  }
  47.3684210526% {
    transform: translateX(-15vw) translateY(-45vh) scale(0.61);
  }
  52.6315789474% {
    transform: translateX(19vw) translateY(8vh) scale(0.75);
  }
  57.8947368421% {
    transform: translateX(-17vw) translateY(-30vh) scale(0.26);
  }
  63.1578947368% {
    transform: translateX(16vw) translateY(-47vh) scale(0.72);
  }
  68.4210526316% {
    transform: translateX(-3vw) translateY(8vh) scale(0.9);
  }
  73.6842105263% {
    transform: translateX(-27vw) translateY(-2vh) scale(0.72);
  }
  78.9473684211% {
    transform: translateX(45vw) translateY(35vh) scale(0.88);
  }
  84.2105263158% {
    transform: translateX(7vw) translateY(41vh) scale(0.35);
  }
  89.4736842105% {
    transform: translateX(47vw) translateY(-34vh) scale(0.7);
  }
  94.7368421053% {
    transform: translateX(-11vw) translateY(42vh) scale(0.93);
  }
  100% {
    transform: translateX(-2vw) translateY(26vh) scale(0.7);
  }
}
.firefly:nth-child(15) {
  animation-name: move15;
}
.firefly:nth-child(15)::before {
  animation-duration: 9s;
}
.firefly:nth-child(15)::after {
  animation-duration: 9s, 10294ms;
  animation-delay: 0ms, 1085ms;
}

@keyframes move15 {
  0% {
    transform: translateX(-14vw) translateY(-35vh) scale(0.99);
  }
  5.5555555556% {
    transform: translateX(-14vw) translateY(14vh) scale(0.5);
  }
  11.1111111111% {
    transform: translateX(31vw) translateY(-11vh) scale(0.64);
  }
  16.6666666667% {
    transform: translateX(1vw) translateY(42vh) scale(0.34);
  }
  22.2222222222% {
    transform: translateX(32vw) translateY(-36vh) scale(0.48);
  }
  27.7777777778% {
    transform: translateX(-16vw) translateY(24vh) scale(0.97);
  }
  33.3333333333% {
    transform: translateX(-44vw) translateY(35vh) scale(0.4);
  }
  38.8888888889% {
    transform: translateX(-11vw) translateY(-20vh) scale(0.35);
  }
  44.4444444444% {
    transform: translateX(-1vw) translateY(-40vh) scale(0.98);
  }
  50% {
    transform: translateX(-35vw) translateY(34vh) scale(0.73);
  }
  55.5555555556% {
    transform: translateX(-14vw) translateY(33vh) scale(0.6);
  }
  61.1111111111% {
    transform: translateX(10vw) translateY(4vh) scale(0.47);
  }
  66.6666666667% {
    transform: translateX(-47vw) translateY(-14vh) scale(0.47);
  }
  72.2222222222% {
    transform: translateX(-22vw) translateY(2vh) scale(0.44);
  }
  77.7777777778% {
    transform: translateX(3vw) translateY(-10vh) scale(0.54);
  }
  83.3333333333% {
    transform: translateX(16vw) translateY(-38vh) scale(0.92);
  }
  88.8888888889% {
    transform: translateX(37vw) translateY(-3vh) scale(0.79);
  }
  94.4444444444% {
    transform: translateX(48vw) translateY(35vh) scale(0.28);
  }
  100% {
    transform: translateX(-44vw) translateY(-26vh) scale(0.68);
  }
}
@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #f24000;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #f24000;
  }
}


// Easter

.easter {
  margin: 0;
  background-color: #161922;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background: radial-gradient(circle, transparent 0%, rgba(41, 22, 75, 0.85) 100%);
}

.easterContainer {
  display: grid;
  grid-template-columns: repeat(10, 100px);
  grid-template-rows: repeat(6, 115px);
  transform: translate(-3%, -10%) scale(2.3);
  transform-origin: top left;
  animation: zoomer 10s infinite;
}

@keyframes zoomer {
  0% {
    transform: translate(-3%, -10%) scale(2.3);
  }
  50% {
    transform: translate(-3%, -10%) scale(3.2);
  }
}



@media only screen and (max-width: 1024px) {
  .easter {
    height: 100vw;
    width: 100vh;
    transform-origin: top left;
    transform: translate(100vw,0) rotate(90deg);
  }
  
  .overlay {
    width: 100%;
    height: 100%;
  }
  
  .easterContainer {
    display: grid;
    grid-template-columns: repeat(10, 100px);
    grid-template-rows: repeat(6, 115px);
    transform: translate(-3%, -4%);
    animation: none;
  }

}

.shape {
  width: 100px;
  height: 115px;
  // -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: url(#hexagonClip);
}
.shape:nth-child(11) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(12) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(13) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(14) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(15) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(16) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(17) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(18) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(19) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(20) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(21) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(21) {
  transform: translate(0%, -50%);
}
.shape:nth-child(22) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(22) {
  transform: translate(0%, -50%);
}
.shape:nth-child(23) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(23) {
  transform: translate(0%, -50%);
}
.shape:nth-child(24) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(24) {
  transform: translate(0%, -50%);
}
.shape:nth-child(25) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(25) {
  transform: translate(0%, -50%);
}
.shape:nth-child(26) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(26) {
  transform: translate(0%, -50%);
}
.shape:nth-child(27) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(27) {
  transform: translate(0%, -50%);
}
.shape:nth-child(28) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(28) {
  transform: translate(0%, -50%);
}
.shape:nth-child(29) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(29) {
  transform: translate(0%, -50%);
}
.shape:nth-child(30) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(30) {
  transform: translate(0%, -50%);
}
.shape:nth-child(31) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(31) {
  transform: translate(0%, -50%);
}
.shape:nth-child(31) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(32) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(32) {
  transform: translate(0%, -50%);
}
.shape:nth-child(32) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(33) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(33) {
  transform: translate(0%, -50%);
}
.shape:nth-child(33) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(34) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(34) {
  transform: translate(0%, -50%);
}
.shape:nth-child(34) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(35) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(35) {
  transform: translate(0%, -50%);
}
.shape:nth-child(35) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(36) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(36) {
  transform: translate(0%, -50%);
}
.shape:nth-child(36) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(37) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(37) {
  transform: translate(0%, -50%);
}
.shape:nth-child(37) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(38) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(38) {
  transform: translate(0%, -50%);
}
.shape:nth-child(38) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(39) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(39) {
  transform: translate(0%, -50%);
}
.shape:nth-child(39) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(40) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(40) {
  transform: translate(0%, -50%);
}
.shape:nth-child(40) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(41) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(41) {
  transform: translate(0%, -50%);
}
.shape:nth-child(41) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(41) {
  transform: translate(0%, -100%);
}
.shape:nth-child(42) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(42) {
  transform: translate(0%, -50%);
}
.shape:nth-child(42) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(42) {
  transform: translate(0%, -100%);
}
.shape:nth-child(43) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(43) {
  transform: translate(0%, -50%);
}
.shape:nth-child(43) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(43) {
  transform: translate(0%, -100%);
}
.shape:nth-child(44) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(44) {
  transform: translate(0%, -50%);
}
.shape:nth-child(44) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(44) {
  transform: translate(0%, -100%);
}
.shape:nth-child(45) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(45) {
  transform: translate(0%, -50%);
}
.shape:nth-child(45) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(45) {
  transform: translate(0%, -100%);
}
.shape:nth-child(46) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(46) {
  transform: translate(0%, -50%);
}
.shape:nth-child(46) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(46) {
  transform: translate(0%, -100%);
}
.shape:nth-child(47) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(47) {
  transform: translate(0%, -50%);
}
.shape:nth-child(47) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(47) {
  transform: translate(0%, -100%);
}
.shape:nth-child(48) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(48) {
  transform: translate(0%, -50%);
}
.shape:nth-child(48) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(48) {
  transform: translate(0%, -100%);
}
.shape:nth-child(49) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(49) {
  transform: translate(0%, -50%);
}
.shape:nth-child(49) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(49) {
  transform: translate(0%, -100%);
}
.shape:nth-child(50) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(50) {
  transform: translate(0%, -50%);
}
.shape:nth-child(50) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(50) {
  transform: translate(0%, -100%);
}
.shape:nth-child(51) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(51) {
  transform: translate(0%, -50%);
}
.shape:nth-child(51) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(51) {
  transform: translate(0%, -100%);
}
.shape:nth-child(51) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(52) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(52) {
  transform: translate(0%, -50%);
}
.shape:nth-child(52) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(52) {
  transform: translate(0%, -100%);
}
.shape:nth-child(52) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(53) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(53) {
  transform: translate(0%, -50%);
}
.shape:nth-child(53) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(53) {
  transform: translate(0%, -100%);
}
.shape:nth-child(53) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(54) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(54) {
  transform: translate(0%, -50%);
}
.shape:nth-child(54) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(54) {
  transform: translate(0%, -100%);
}
.shape:nth-child(54) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(55) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(55) {
  transform: translate(0%, -50%);
}
.shape:nth-child(55) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(55) {
  transform: translate(0%, -100%);
}
.shape:nth-child(55) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(56) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(56) {
  transform: translate(0%, -50%);
}
.shape:nth-child(56) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(56) {
  transform: translate(0%, -100%);
}
.shape:nth-child(56) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(57) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(57) {
  transform: translate(0%, -50%);
}
.shape:nth-child(57) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(57) {
  transform: translate(0%, -100%);
}
.shape:nth-child(57) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(58) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(58) {
  transform: translate(0%, -50%);
}
.shape:nth-child(58) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(58) {
  transform: translate(0%, -100%);
}
.shape:nth-child(58) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(59) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(59) {
  transform: translate(0%, -50%);
}
.shape:nth-child(59) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(59) {
  transform: translate(0%, -100%);
}
.shape:nth-child(59) {
  transform: translate(-50%, -125%);
}
.shape:nth-child(60) {
  transform: translate(-50%, -25%);
}
.shape:nth-child(60) {
  transform: translate(0%, -50%);
}
.shape:nth-child(60) {
  transform: translate(-50%, -75%);
}
.shape:nth-child(60) {
  transform: translate(0%, -100%);
}
.shape:nth-child(60) {
  transform: translate(-50%, -125%);
}